import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import IntroSection from "./introStyle"
import { H2, P } from "../../util/standard"
import BlockContent from "@sanity/block-content-to-react"

const DonateIntro = () => {
  const {
    sanityDonationBricks: { intro },
  } = useStaticQuery(graphql`
    query {
      sanityDonationBricks {
        intro {
          title
          description {
            _key
            _type
            _rawContent
          }
        }
      }
    }
  `)

  return (
    <IntroSection>
      <H2>{intro.title}</H2>
      <BlockContent blocks={intro.description._rawContent} />
    </IntroSection>
  )
}

export default DonateIntro
