import styled from "styled-components"

export default styled.section`
  width: 80%;
  max-width: 1000px;
  margin: 70px auto;

  h2 {
    font-weight: bold;
  }
`