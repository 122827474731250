import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DonateHero from "../components/bricks/hero";
import DonateIntro from "../components/bricks/intro";
import DefaultBricks from "../components/bricks/defaultBricks";
import BrickBlocks from "../components/bricks/bricks";
import DonateBlock from "../components/shared/DonateBlock";
import SEO from "../components/shared/Seo";

const DonatePage = () => {
  const { sanityDonationBricks } = useStaticQuery(graphql`
    query {
      sanityDonationBricks {
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO seoData={sanityDonationBricks?.seoBlock} slug="donate" />
      <DonateHero />
      <DonateIntro />
      <DefaultBricks />
      <BrickBlocks />
      {/* <InfoBlock /> */}
      <DonateBlock />
    </div>
  );
};

export default DonatePage;
